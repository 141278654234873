















































































import { Component, Vue } from "vue-property-decorator";
import Cta from "@/components/Cta.vue";
import Gallery from "@/components/Gallery.vue";
@Component({
  components: { Gallery, Cta },
})
export default class Accommodation extends Vue {
  tripTab = null;
}
